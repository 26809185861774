<script setup>
import { ref, onBeforeMount } from "vue";
import { useProfileStore } from "@/stores/profileStore";
import { useRouter } from "vue-router/composables";
import { storeToRefs } from "pinia";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import ButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompProfileEditPicture from "@/components/profile/edit/CompProfileEditPicture.vue";
import CompProfileEditBasicInformation from "@/components/profile/edit/CompProfileEditBasicInformation.vue";
import CompProfileEditCoupleInformation from "@/components/profile/edit/CompProfileEditCoupleInformation.vue";
import CompProfileEditChildren from "@/components/profile/edit/CompProfileEditChildren.vue";
import CompProfileEditPets from "@/components/profile/edit/CompProfileEditPets.vue";
import CompProfileEditMultipleChoice from "@/components/profile/edit/CompProfileEditMultipleChoice.vue";
import CompProfileEditCity from "@/components/profile/edit/CompProfileEditCity.vue";
import CompProfileEditDependents from "@/components/profile/edit/CompProfileEditDependents.vue";
import CompProfileEditHouseWork from "@/components/profile/edit/CompProfileEditHouseWork.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompProfileEditSkeleton from "@/components/profile/skeletons/CompProfileEditSkeleton.vue";
import CompProfileEditChildrenSupport from "@/components/profile/edit/CompProfileEditChildrenSupport.vue";

const loadingProfile = ref(false);
const isSaveProfileModalOpen = ref(false);
const responseMessage = ref("Hemos guardado tus cambios");
const responseSuccess = ref(true);
const responseErrors = ref([]);
const malts = ref(false);
const router = useRouter();
const {
  userProfileData,
  // communitiesCollection,
  disabilitiesCollection,
  // familyTypesCollection,
  // attractedToCollection,
  ethnicGroupsCollection,
  // genderIdentitiesCollection,
  hobbiesCollection,
  isProfileSaved,
} = storeToRefs(useProfileStore());
const {
  actGetPets,
  actGetProfileData,
  actPostProfileData,
  actGetCollectionPronouns,
  actGetCollectionCommunities,
  actGetCollectionDisabilities,
  actGetCollectionFamilyTypes,
  actGetCollectionAttractedTo,
  actGetCollectionEthnicGroups,
  actGetCollectionGenders,
  actGetCollectionGenderIdentities,
  actGetCollectionHobbies,
} = useProfileStore();

const updateProfile = async () => {
  pushDataLayer({
    event_category: "Perfil",
    event_action: "Edita tu perfil",
    event_label: "Guardar cambios",
    component_name: "Boton",
    element_text: "Guardar cambios",
    profile_gender: userProfileData.value.gender,
    profile_pronoun: userProfileData.value.pronoun,
    profile_roomies: userProfileData.value.family_types,
    profile_children: userProfileData.value.children,
    profile_pets: userProfileData.value.pets,
    profile_hobbies: userProfileData.value.hobbies,
  });

  await actPostProfileData(userProfileData.value).then((res) => {
    if (res.status && res.status !== 200) {
      const { data } = res;
      responseSuccess.value = false;
      responseMessage.value = data.message;
      responseErrors.value = Object.values(data.errors).flat();
      isProfileSaved.value = false;
    } else {
      responseMessage.value = "Hemos guardado tus cambios";
      responseSuccess.value = true;
      malts.value = res.mission_malts;
      isProfileSaved.value = true;
    }
  });

  isSaveProfileModalOpen.value = true;
};

const closeProfileModal = () => {
  if (responseSuccess.value) {
    isSaveProfileModalOpen.value = false;
    router.push({ name: "Profile" });
  } else {
    isSaveProfileModalOpen.value = false;
  }
};

function handleUpdate(updatedData) {
  // Actualiza userProfileData con los datos recibidos
  Object.keys(updatedData).forEach((key) => {
    userProfileData.value[key] = updatedData[key];
  });
}

onBeforeMount(async () => {
  loadingProfile.value = true;

  await Promise.all([
    actGetProfileData(),
    actGetPets(),
    actGetCollectionPronouns(),
    actGetCollectionCommunities(),
    actGetCollectionDisabilities(),
    actGetCollectionFamilyTypes(),
    actGetCollectionAttractedTo(),
    actGetCollectionEthnicGroups(),
    actGetCollectionGenders(),
    actGetCollectionGenderIdentities(),
    actGetCollectionHobbies(),
  ]);

  /*  profileStore.validateUserEditData(); */

  loadingProfile.value = false;
});
</script>

<template>
  <div>
    <portal-target name="profileHome" />
    <general-modal
      :maxHeight="{ base: 400, md: 500, lg: 600, xl: 700 }"
      :modalWidth="{ md: 400, lg: 600, xl: 600 }"
      modal-name="saveProfileModal"
      v-if="isSaveProfileModalOpen"
      :isShowModal="isSaveProfileModalOpen"
      @close="closeProfileModal"
    >
      <div
        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3"
        v-if="!malts"
      >
        <div
          class="tw-flex tw-w-7/12 tw-justify-between tw-items-center tw-gap-4"
        >
          <img
            src="@/assets/img/modal/modal-success.svg"
            alt=""
            v-if="responseSuccess"
          />
          <img src="@/assets/img/modal/modal-error.svg" alt="" v-else />
          <p
            class="tw-m-0 tw-font-robotoBold tw-text-3xl lg:tw-text-4xl"
            :class="responseSuccess ? 'tw-text-green-450' : 'tw-text-red-350'"
          >
            {{ responseSuccess ? "LISTO" : "LO SENTIMOS" }}
          </p>
        </div>
        <div id="errors">
          <p
            v-for="(error, index) in responseErrors"
            :key="index"
            class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
          >
            {{ error }}
          </p>
        </div>
        <button-primary-red
          rounded-size="tw-rounded-lg lg:tw-rounded-xl"
          @click.native="closeProfileModal"
          title="Cerrar"
          class="tw-w-full"
        />
      </div>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3"
        v-else
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-3xl">
            ¡Felicidades!
          </p>
          <div class="tw-flex tw-gap-3 tw-items-center">
            <p class="tw-m-0 tw-font-robotoBold tw-text-red-350 tw-text-2xl">
              Recibiste:
              <span class="tw-text-black">{{ `${malts} maltas` }}</span>
            </p>
            <i class="icon-cebada-full tw-text-3xl tw-text-yellow-300" />
          </div>
          <p>Por completar tu perfil, podrás usar tus maltas próximamente.</p>
        </div>
        <button-primary-red
          rounded-size="tw-rounded-lg lg:tw-rounded-xl"
          @click.native="closeProfileModal"
          title="Cerrar"
          class="tw-w-full"
        />
      </div>
    </general-modal>
    <section
      class="tw-h-36 tw-bg-cover tw-bg-center"
      :style="{
        'background-image': `url(${require('@/assets/img/pokerfondo.jpeg')})`,
      }"
      v-if="!loadingProfile"
    />
    <div
      class="tw-h-36 tw-w-full tw-bg-gray-300 tw-animate-pulse"
      v-if="loadingProfile"
    />

    <main class="tw-container-general" v-if="!loadingProfile">
      <p class="tw-font-robotoBold tw-text-lg lg:tw-text-2xl">
        Edita tu perfil
      </p>
      <section
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-8"
      >
        <div class="tw-flex tw-flex-col tw-gap-4">
          <comp-profile-edit-picture />
          <comp-profile-edit-basic-information
            :data="userProfileData"
            :profile-saved="isProfileSaved"
            @update="handleUpdate"
          />
          <comp-profile-edit-city
            :data="userProfileData"
            @update="handleUpdate"
          />
<!--          <comp-profile-edit-multiple-choice
            name="attracted_to"
            description="Te sientes atraído/a/e emocional, física y sexualmente hacia:"
            :collection="attractedToCollection"
            :data="userProfileData"
            collection-name="attracted_to"
            @update="handleUpdate"
            single-choice
          />-->
          <comp-profile-edit-couple-information
            :data="userProfileData"
            @update="handleUpdate"
          />
          <div
            class="tw-p-3 tw-bg-yellow-300 tw-flex tw-items-center tw-gap-2 tw-justify-center"
          >
            Si tienes dudas selecciona la <i class="icon-mas-informacion" /> y
            conoce su significado
          </div>
<!--          <comp-profile-edit-multiple-choice
            name="communities"
            description="Si en tu entorno personal hay personas que forman parte de alguna minoría, selecciónalas a continuación."
            :collection="communitiesCollection"
            :data="userProfileData"
            collection-name="communities_in_members"
            @update="handleUpdate"
          />-->
<!--          <comp-profile-edit-multiple-choice
            name="genderIdentities"
            description="¿Con qué identidad de género te sientes identificado/a?"
            :collection="genderIdentitiesCollection"
            :data="userProfileData"
            collection-name="gender_identity"
            @update="handleUpdate"
            single-choice
          />-->
          <comp-profile-edit-multiple-choice
            name="ethnic_group"
            description="¿Con qué etnia te reconoces, te sientes identificado/a?"
            :collection="ethnicGroupsCollection"
            :data="userProfileData"
            collection-name="ethnic_group"
            @update="handleUpdate"
            single-choice
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <comp-profile-edit-multiple-choice
            name="disabilities"
            description="¿Tienes algún tipo de discapacidad?*"
            :collection="disabilitiesCollection"
            :data="userProfileData"
            collection-name="disabilities"
            @update="handleUpdate"
          />
<!--          <comp-profile-edit-multiple-choice
            name="familyTypes"
            description="¿Qué tipología de familia tienes?*"
            :collection="familyTypesCollection"
            :data="userProfileData"
            collection-name="family_types"
            @update="handleUpdate"
          />-->
          <comp-profile-edit-dependents
            :data="userProfileData"
            @update="handleUpdate"
          />
          <comp-profile-edit-house-work
            :data="userProfileData"
            @update="handleUpdate"
          />
          <comp-profile-edit-children
            :data="userProfileData"
            @update="handleUpdate"
          />
          <comp-profile-edit-children-support
            v-if="userProfileData.has_children === 'yes'"
            :data="userProfileData"
            @update="handleUpdate"
          />
          <comp-profile-edit-pets
            :data="userProfileData"
            @update="handleUpdate"
          />
          <comp-profile-edit-multiple-choice
            name="hobbies"
            description="¿Cuales son tus hobbies?*"
            :collection="hobbiesCollection"
            :data="userProfileData"
            collection-name="hobbies"
            @update="handleUpdate"
            allow-additional-items
          />
          <button-primary-red
            rounded-size="tw-rounded-xl lg:tw-rounded-3xl"
            @click.native="updateProfile"
            title="Guardar cambios"
            class="tw-w-full tw-my-2 lg:tw-my-4"
          />
        </div>
      </section>
    </main>
    <div v-if="loadingProfile" class="tw-container-general">
      <comp-profile-edit-skeleton />
    </div>
  </div>
</template>
