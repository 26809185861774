var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.isSplash)?_c('div',[_c(_setup.GeneralSplash,{attrs:{"title":"Beneficios de Tiempo","icon":"icon-inside.svg","illustration":"illustration-goflex.svg"},on:{"loaded":function($event){_setup.isSplash = false}}},[_vm._v(" Tenemos noticias importantes "),_c('strong',{staticClass:"tw-font-robotoBold"},[_vm._v("para tí.")])])],1):_c('div',[_c('portal',{attrs:{"to":"outer-modals"}},[_c(_setup.GeneralModal,{attrs:{"maxHeight":{ base: 600, md: 650, lg: 650, xl: 700, '2xl': 1000 },"modalWidth":{ md: 400, lg: 600, xl: 800, '2xl': 1000 },"width-fit":"","modal-name":"no-more-goflex","is-show-modal":"","overall":""},on:{"close":function($event){return _setup.closeModal()}}},[_c('img',{staticClass:"tw-w-full tw-h-[550px] md:tw-h-[550px] lg:tw-h-[600px] xl:tw-h-[650px] 2xl:tw-h-[800px] tw-object-contain",attrs:{"src":require("@/assets/img/goflex/lastinfo.jpg"),"alt":""}})])],1),_c(_setup.MobileBar,{attrs:{"title":"Beneficios de Tiempo"}}),_c(_setup.TitleBar,{attrs:{"title":"Beneficios de Tiempo"}}),(!_setup.isLoading)?_c('main',{staticClass:"tw-container-general"},[_c(_setup.CompGoFlexTopButtons),_c(_setup.CompGoFlexPrincipalCard),_c('hr',{staticClass:"tw-w-full tw-my-5 lg:tw-my-10"}),_c('section',{staticClass:"tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl"},[_vm._v(" Catálogo ")]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-justify-between lg:tw-justify-start tw-gap-4 tw-w-full lg:tw-w-1/3"},[_c('div',{staticClass:"tw-flex tw-items-center tw-w-full tw-gap-4"},[_c('div',{staticClass:"tw-btn-general-shape tw-border tw-border-green-500 tw-flex tw-justify-center tw-items-center tw-gap-2",class:_setup.catalogFilter === 'flex'
                    ? 'tw-bg-green-500 tw-text-white'
                    : 'tw-text-green-500',on:{"click":function($event){return _setup.changeFilter('flex')}}},[_c('i',{staticClass:"icon-cebada-full"}),_c('p',{staticClass:"tw-m-0"},[_vm._v("Flex work")])]),_c('div',{staticClass:"tw-btn-general-shape tw-border tw-border-orange-350 tw-flex tw-justify-center tw-items-center tw-gap-2",class:_setup.catalogFilter === 'off'
                    ? 'tw-bg-orange-350 tw-text-white'
                    : 'tw-text-orange-350',on:{"click":function($event){return _setup.changeFilter('off')}}},[_c('i',{staticClass:"icon-cebada-full"}),_c('p',{staticClass:"tw-m-0"},[_vm._v("Tiempo + off")])])])]),(!_setup.isLoading && _setup.goFlexStore.catalog != null)?_c('div',{staticClass:"tw-w-max tw-self-center"},[_c('div',{staticClass:"tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-flex tw-items-center tw-justify-between tw-gap-3 tw-py-2 lg:tw-py-3"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoMedium tw-text-lg lg:tw-text-xl"},[_vm._v(" Tienes "+_vm._s(_setup.goFlexStore.catalog.points_balance)+" puntos ")]),_vm._m(0)])]):_vm._e()])]),(!_setup.hasLineManager)?_c('section',{staticClass:"disclaimer tw-p-4 tw-bg-pink-100 tw-mt-4 lg:tw-mt-8"},[_vm._m(1)]):_vm._e(),_c('section',{staticClass:"tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-mt-5 lg:tw-mt-10"},[(
            (!_setup.isLoading &&
              _setup.goFlexStore.catalog != null &&
              _setup.goFlexStore.catalog.points_balance === 0) ||
            _setup.goFlexStore.catalog == null
          )?_c('div',{staticClass:"tw-w-full tw-rounded-3xl tw-border tw-border-red-350 tw-p-4 lg:tw-p-10 tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-3"},[_vm._m(2),_c('div',{staticClass:"lg:tw-col-span-9 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6 tw-justify-end"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoMedium tw-text-base lg:tw-text-3xl lg:tw-leading-12 tw-text-center lg:tw-text-left"},[_vm._v(" Lo sentimos, en este momento no tienes ningún producto de "+_vm._s(_setup.catalogFilter === "flex" ? "Flex work" : "Tiempo + Off")+" disponible. ")]),_c('img',{staticClass:"tw-max-h-7 lg:tw-max-h-12 tw-max-w-full tw-object-contain tw-self-end",attrs:{"src":require("@/assets/img/icons/icon-1-main-navbar.png"),"alt":""}})])]):_vm._e(),(
            !_setup.isLoading &&
            _setup.goFlexStore.catalog != null &&
            _setup.goFlexStore.catalog.points_balance > 0
          )?_c('div',{staticClass:"tw-grid tw-grid-cols-2 lg:tw-grid-cols-5 tw-gap-4 lg:tw-gap-6"},_vm._l((_setup.goFlexStore.catalog.options),function(item,idx){return _c('div',{key:idx},[_c('div',{staticClass:"tw-h-full tw-rounded-2.5xl lg:tw-rounded-3xl tw-border tw-px-3 tw-py-2 lg:tw-px-5 lg:tw-py-3 tw-flex tw-flex-col tw-gap-2 lg:tw-gap-3 tw-cursor-pointer",class:[
                !_setup.enoughPoints(item.points) || !_setup.hasLineManager
                  ? 'tw-border-gray-300'
                  : _setup.catalogFilter === 'off'
                  ? 'tw-border-orange-350'
                  : 'tw-border-green-500',
              ],on:{"click":function($event){_setup.enoughPoints(item.points) && _setup.hasLineManager
                  ? _setup.goToItemDetail(item)
                  : ''}}},[_c('div',{staticClass:"tw-aspect-h-1 tw-aspect-w-1 tw-max-h-32",class:!_setup.enoughPoints(item.points) ? 'tw-grayscale' : ''},[_c('img',{staticClass:"tw-w-full tw-rounded-2xl lg:tw-rounded-2.5xl tw-object-cover",attrs:{"src":_setup.uriResources + '/' + item.image,"alt":""}})]),_c('div',{staticClass:"tw-flex tw-flex-col tw-h-full tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-3"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-xl",class:!_setup.enoughPoints(item.points) || !_setup.hasLineManager
                        ? 'tw-text-gray-300'
                        : _setup.catalogFilter === 'off'
                        ? 'tw-text-orange-350'
                        : 'tw-text-green-500'},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base",class:!_setup.enoughPoints(item.points) || !_setup.hasLineManager
                        ? 'tw-text-gray-300'
                        : ''},[_vm._v(" "+_vm._s(item.label)+" ")])]),_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg tw-text-center",class:!_setup.enoughPoints(item.points) || !_setup.hasLineManager
                      ? 'tw-text-gray-300'
                      : _setup.catalogFilter === 'off'
                      ? 'tw-text-orange-350'
                      : 'tw-text-green-500'},[_vm._v(" "+_vm._s(item.points + " Puntos")+" ")])])])])}),0):_vm._e()]),_c('section',{staticClass:"tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-mt-4 lg:tw-mt-8"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl"},[_vm._v(" Tips de "+_vm._s(_setup.catalogFilter === "off" ? "Tiempo +OFF" : "Flex work")+" ")]),_c('div',{staticClass:"tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-6"},_vm._l((_setup.filteredTips),function(tip,idx){return _c('div',{key:idx},[_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-h-full"},[_c('div',{staticClass:"tw-col-span-1 tw-rounded-tl-lg lg:tw-rounded-tl-xl tw-rounded-bl-lg lg:tw-rounded-bl-xl tw-overflow-hidden"},[_c('img',{staticClass:"tw-w-full tw-h-full tw-object-cover",attrs:{"src":tip.image,"alt":""}})]),_c('div',{staticClass:"tw-col-span-2 tw-p-3 lg:tw-p-4 tw-grid tw-grid-rows-6 tw-gap-2 tw-border tw-border-l-0 tw-rounded-tr-lg lg:tw-rounded-tr-xl tw-rounded-br-lg lg:tw-rounded-br-xl",class:_setup.catalogFilter === 'off'
                    ? 'tw-border-orange-350'
                    : 'tw-border-green-500'},[_c('div',{staticClass:"tw-row-span-2"},[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-base",class:_setup.catalogFilter === 'off'
                        ? 'tw-text-orange-350'
                        : 'tw-text-green-500'},[_vm._v(" "+_vm._s(tip.title)+" ")])]),_c('div',{staticClass:"tw-row-span-4"},[_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-5 tw-break-words"},[_vm._v(" "+_vm._s(tip.description)+" ")])])])])])}),0)])],1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-1"},[_c('i',{staticClass:"icon-star tw-golden-black-border-icon"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-container tw-mx-auto tw-max-w-screen-xl tw-px-5 md:tw-px-6 lg:tw-px-8 2xl:tw-px-0 lg:tw-my-0 tw-flex tw-justify-center lg:tw-justify-evenly tw-items-center tw-gap-2"},[_c('div',{staticClass:"tw-h-14 tw-hidden lg:tw-block"},[_c('img',{staticClass:"tw-object-contain tw-h-full",attrs:{"src":require("@/assets/img/splash/illustration-goflex.svg"),"alt":""}})]),_c('p',{staticClass:"tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-text-center"},[_vm._v(" No tienes un line manager asignado, comunícate con "),_c('span',{staticClass:"tw-text-red-350"},[_vm._v("maria.lovera@ab-inbev.com")]),_vm._v(" para actualizar los datos y acceder a tus beneficios. ")]),_c('div',{staticClass:"tw-h-6 tw-hidden lg:tw-block"},[_c('img',{staticClass:"tw-object-contain tw-h-full",attrs:{"src":require("@/assets/img/splash/icon-inside.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-grid lg:tw-col-span-3"},[_c('img',{staticClass:"tw-w-7/12 lg:tw-w-9/12 tw-object-contain tw-place-self-center",attrs:{"src":require("@/assets/img/splash/illustration-goflex.svg"),"alt":""}})])
}]

export { render, staticRenderFns }