<script setup>
import { ref, computed, onBeforeMount, watch } from "vue";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useRouter } from "vue-router/composables";
import { useGoFlexStore } from "@/stores/goFlexStore";
import { useUserStore } from "@/stores/userStore";
import config from "@/services/config";

import GeneralSplash from "@/components/general/GeneralSplash.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import TitleBar from "@/components/general/TitleBar.vue";
import CompGoFlexPrincipalCard from "@/components/goFlex/CompGoFlexPrincipalCard.vue";
import CompGoFlexTopButtons from "@/components/goFlex/CompGoFlexTopButtons.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";

const router = useRouter();
const goFlexStore = useGoFlexStore();
const userStore = useUserStore();
const isSplash = ref(true);
const isLoading = ref(true);
const catalogFilter = ref("flex");
const uriResources = config.beerOfficeURL;

const tips = ref([
  {
    id: 0,
    title: "Tiempo +OFF es un beneficio de tiempo libre",
    description:
      "Cada persona debe planificar sus tiempos, ser responsable con sus labores, dejar organizados sus pendientes y coordinar con su líder.",
    image: require("@/assets/img/goflex/tips/tip_off_1.jpg"),
    category: "off",
  },
  {
    id: 1,
    title: "¿Por qué no puedo pedir mi TIEMPO OFF?",
    description:
      "Recuerda que, si tienes más de 1 período de vacaciones acumulado a corte 31 de diciembre, no podrás gozar de este beneficio.",
    image: require("@/assets/img/goflex/tips/tip_off_2.jpg"),
    category: "off",
  },
  {
    id: 2,
    title: "Algunos ejemplos para tomar mi TIEMPO OFF.",
    description:
      "• “Tuve que hacer un trámite personal”" +
      "\n" +
      "• “Tuve una semana de jornada extendida y tomé un viernes corto”",
    image: require("@/assets/img/goflex/tips/tip_off_3.jpg"),
    category: "off",
  },
  {
    id: 3,
    title: "Tiempo +OFF y Flex work",
    description:
      "Si necesitas tomarte medio día para una eventualidad personal y es mas fácil trabajar desde casa, ahora puedes hacer una solicitud flex work y una time off para el mismo día.",
    image: require("@/assets/img/goflex/tips/tip_off_4.jpg"),
    category: "off",
  },
  {
    id: 4,
    title: "¿Qué no debes hacer en este tiempo?",
    description:
      "En este tiempo no se espera que las personas trabajen o participen en reuniones.",
    image: require("@/assets/img/goflex/tips/tip_off_5.jpg"),
    category: "off",
  },
  {
    id: 5,
    title: "TIEMPO OFF no son vacaciones.",
    description:
      "La diferencia entre Time Off y las vacaciones es que las vacaciones son días de descanso consecutivo que tienes legalmente.",
    image: require("@/assets/img/goflex/tips/tip_off_6.jpg"),
    category: "off",
  },
  {
    id: 6,
    title: "¿Qué debes hacer para solicitar tu FLEX WORK?",
    description:
      "Debes coordinar/aprobar con el líder y el equipo para hacerlo. Recuerda que todo se basa en la confianza.",
    image: require("@/assets/img/goflex/tips/tip_flex_1.jpg"),
    category: "flex",
  },
  {
    id: 7,
    title: "¿Dónde trabajar en modo FLEX WORK?",
    description:
      "Debes realizarlo donde se garantice los resultados, la confidencialidad y la disposición para interacciones virtuales.",
    image: require("@/assets/img/goflex/tips/tip_flex_2.jpg"),
    category: "flex",
  },
  {
    id: 8,
    title: "Algunos ejemplos para tomar Flex Work.",
    description:
      "“Tenía un día de reuniones virtuales, las tomé en un café” \n “Trabajé desde casa mientras recibía asistencia para la revisión del gas”",
    image: require("@/assets/img/goflex/tips/tip_flex_3.jpg"),
    category: "flex",
  },
  {
    id: 9,
    title: "Tiempo +OFF y Flex work",
    description:
      "Si necesitas tomarte medio día para una eventualidad personal y es mas fácil trabajar desde casa, ahora puedes hacer una solicitud flex work y una time off para el mismo día.",
    image: require("@/assets/img/goflex/tips/tip_flex_4.jpg"),
    category: "flex",
  },
  {
    id: 10,
    title: "Priorizamos la interacción personal.",
    description:
      "FLEX WORK es un beneficio que se puede tomar algunos días para trabajar desde un lugar diferente a la oficina.",
    image: require("@/assets/img/goflex/tips/tip_flex_5.jpg"),
    category: "flex",
  },
  {
    id: 11,
    title: "No es una modalidad de teletrabajo.",
    description:
      "Si quieres hacer uso del beneficio FlexWork debes garantizar conectividad y tus herramientas necesarias para tu labor fuera de la oficina.",
    image: require("@/assets/img/goflex/tips/tip_flex_6.jpg"),
    category: "flex",
  },
]);

const filteredTips = computed(() => {
  return tips.value.filter((tip) => tip.category === catalogFilter.value);
});

const hasLineManager = computed(() => {
  return (
    userStore.user.line_manager_id_sharp != null &&
    userStore.user.line_manager_id_sharp !== "" &&
    userStore.user.line_manager_id_sharp !== "0"
  );
});

function changeFilter(filter) {
  catalogFilter.value = filter;
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Catalogo",
    event_label: filter === "flex" ? "Flex Work" : "Tiempo + Off",
    component_name: "Click",
  });
}

function enoughPoints(item_points) {
  return goFlexStore.catalog.points_balance >= item_points;
}

function goToItemDetail(item) {
  pushDataLayer({
    event_category: "Go Flex",
    event_action: "Catalogo",
    event_label: item.name,
    component_name: "Click",
    element_category:
      catalogFilter.value === "flex" ? "Flex Work" : "Tiempo + Off",
    element_points: item.points + " puntos",
  });
  router.push({
    name: "goFlexDetail",
    params: {
      id: item.id,
      item: item,
      points: goFlexStore.catalog.points_balance,
    },
  });
}

async function getCatalog() {
  await goFlexStore.actGetGoFlexCatalog(catalogFilter.value);
  isLoading.value = false;
}

const closeModal = () => {
  router.push({ name: "Home" });
};

watch(catalogFilter, () => {
  getCatalog();
});

onBeforeMount(async () => {
  isLoading.value = true;
  goFlexStore.requests.length === 0
    ? await goFlexStore.actGetGoFlexRequests()
    : null;
  await getCatalog();
});
</script>

<template>
  <div>
    <div v-if="isSplash">
      <general-splash
        title="Beneficios de Tiempo"
        icon="icon-inside.svg"
        illustration="illustration-goflex.svg"
        @loaded="isSplash = false"
      >
        Tenemos noticias importantes
        <strong class="tw-font-robotoBold">para tí.</strong>
      </general-splash>
    </div>
    <div v-else>
      <portal to="outer-modals">
        <general-modal
          :maxHeight="{ base: 600, md: 650, lg: 650, xl: 700, '2xl': 1000 }"
          :modalWidth="{ md: 400, lg: 600, xl: 800, '2xl': 1000 }"
          width-fit
          modal-name="no-more-goflex"
          is-show-modal
          overall
          @close="closeModal()"
        >
          <img
            src="@/assets/img/goflex/lastinfo.jpg"
            alt=""
            class="tw-w-full tw-h-[550px] md:tw-h-[550px] lg:tw-h-[600px] xl:tw-h-[650px] 2xl:tw-h-[800px] tw-object-contain"
          />
        </general-modal>
      </portal>
      <mobile-bar title="Beneficios de Tiempo" />
      <title-bar title="Beneficios de Tiempo" />
      <main class="tw-container-general" v-if="!isLoading">
        <comp-go-flex-top-buttons />
        <comp-go-flex-principal-card />
        <hr class="tw-w-full tw-my-5 lg:tw-my-10" />
        <!-- catalogo -->
        <section class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8">
          <p class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl">
            Catálogo
          </p>
          <div
            class="tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-justify-between"
          >
            <div
              class="tw-flex tw-justify-between lg:tw-justify-start tw-gap-4 tw-w-full lg:tw-w-1/3"
            >
              <div class="tw-flex tw-items-center tw-w-full tw-gap-4">
                <div
                  class="tw-btn-general-shape tw-border tw-border-green-500 tw-flex tw-justify-center tw-items-center tw-gap-2"
                  :class="
                    catalogFilter === 'flex'
                      ? 'tw-bg-green-500 tw-text-white'
                      : 'tw-text-green-500'
                  "
                  @click="changeFilter('flex')"
                >
                  <i class="icon-cebada-full"></i>
                  <p class="tw-m-0">Flex work</p>
                </div>
                <div
                  class="tw-btn-general-shape tw-border tw-border-orange-350 tw-flex tw-justify-center tw-items-center tw-gap-2"
                  :class="
                    catalogFilter === 'off'
                      ? 'tw-bg-orange-350 tw-text-white'
                      : 'tw-text-orange-350'
                  "
                  @click="changeFilter('off')"
                >
                  <i class="icon-cebada-full"></i>
                  <p class="tw-m-0">Tiempo + off</p>
                </div>
              </div>
            </div>
            <div
              class="tw-w-max tw-self-center"
              v-if="!isLoading && goFlexStore.catalog != null"
            >
              <div
                class="tw-border tw-border-t tw-border-b tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-flex tw-items-center tw-justify-between tw-gap-3 tw-py-2 lg:tw-py-3"
              >
                <p class="tw-m-0 tw-font-robotoMedium tw-text-lg lg:tw-text-xl">
                  Tienes {{ goFlexStore.catalog.points_balance }} puntos
                </p>
                <div
                  class="tw-rounded-full tw-border tw-border-dashed tw-flex tw-p-1"
                >
                  <i class="icon-star tw-golden-black-border-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="disclaimer tw-p-4 tw-bg-pink-100 tw-mt-4 lg:tw-mt-8"
          v-if="!hasLineManager"
        >
          <div
            class="tw-container tw-mx-auto tw-max-w-screen-xl tw-px-5 md:tw-px-6 lg:tw-px-8 2xl:tw-px-0 lg:tw-my-0 tw-flex tw-justify-center lg:tw-justify-evenly tw-items-center tw-gap-2"
          >
            <div class="tw-h-14 tw-hidden lg:tw-block">
              <img
                src="@/assets/img/splash/illustration-goflex.svg"
                class="tw-object-contain tw-h-full"
                alt=""
              />
            </div>
            <p
              class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base tw-text-center"
            >
              No tienes un line manager asignado, comunícate con
              <span class="tw-text-red-350">maria.lovera@ab-inbev.com</span>
              para actualizar los datos y acceder a tus beneficios.
            </p>
            <div class="tw-h-6 tw-hidden lg:tw-block">
              <img
                src="@/assets/img/splash/icon-inside.svg"
                class="tw-object-contain tw-h-full"
                alt=""
              />
            </div>
          </div>
        </section>
        <section
          class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-mt-5 lg:tw-mt-10"
        >
          <div
            class="tw-w-full tw-rounded-3xl tw-border tw-border-red-350 tw-p-4 lg:tw-p-10 tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-3"
            v-if="
              (!isLoading &&
                goFlexStore.catalog != null &&
                goFlexStore.catalog.points_balance === 0) ||
              goFlexStore.catalog == null
            "
          >
            <div class="tw-grid lg:tw-col-span-3">
              <img
                src="@/assets/img/splash/illustration-goflex.svg"
                class="tw-w-7/12 lg:tw-w-9/12 tw-object-contain tw-place-self-center"
                alt=""
              />
            </div>
            <div
              class="lg:tw-col-span-9 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-6 tw-justify-end"
            >
              <p
                class="tw-m-0 tw-font-robotoMedium tw-text-base lg:tw-text-3xl lg:tw-leading-12 tw-text-center lg:tw-text-left"
              >
                Lo sentimos, en este momento no tienes ningún producto de
                {{ catalogFilter === "flex" ? "Flex work" : "Tiempo + Off" }}
                disponible.
              </p>
              <img
                src="@/assets/img/icons/icon-1-main-navbar.png"
                class="tw-max-h-7 lg:tw-max-h-12 tw-max-w-full tw-object-contain tw-self-end"
                alt=""
              />
            </div>
          </div>
          <div
            class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-5 tw-gap-4 lg:tw-gap-6"
            v-if="
              !isLoading &&
              goFlexStore.catalog != null &&
              goFlexStore.catalog.points_balance > 0
            "
          >
            <div v-for="(item, idx) in goFlexStore.catalog.options" :key="idx">
              <div
                class="tw-h-full tw-rounded-2.5xl lg:tw-rounded-3xl tw-border tw-px-3 tw-py-2 lg:tw-px-5 lg:tw-py-3 tw-flex tw-flex-col tw-gap-2 lg:tw-gap-3 tw-cursor-pointer"
                :class="[
                  !enoughPoints(item.points) || !hasLineManager
                    ? 'tw-border-gray-300'
                    : catalogFilter === 'off'
                    ? 'tw-border-orange-350'
                    : 'tw-border-green-500',
                ]"
                @click="
                  enoughPoints(item.points) && hasLineManager
                    ? goToItemDetail(item)
                    : ''
                "
              >
                <div
                  class="tw-aspect-h-1 tw-aspect-w-1 tw-max-h-32"
                  :class="!enoughPoints(item.points) ? 'tw-grayscale' : ''"
                >
                  <img
                    :src="uriResources + '/' + item.image"
                    alt=""
                    class="tw-w-full tw-rounded-2xl lg:tw-rounded-2.5xl tw-object-cover"
                  />
                </div>
                <div class="tw-flex tw-flex-col tw-h-full tw-justify-between">
                  <div class="tw-flex tw-flex-col tw-gap-3">
                    <p
                      class="tw-m-0 tw-font-robotoBold tw-text-lg lg:tw-text-xl"
                      :class="
                        !enoughPoints(item.points) || !hasLineManager
                          ? 'tw-text-gray-300'
                          : catalogFilter === 'off'
                          ? 'tw-text-orange-350'
                          : 'tw-text-green-500'
                      "
                    >
                      {{ item.name }}
                    </p>
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-base"
                      :class="
                        !enoughPoints(item.points) || !hasLineManager
                          ? 'tw-text-gray-300'
                          : ''
                      "
                    >
                      {{ item.label }}
                    </p>
                  </div>
                  <p
                    class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-lg tw-text-center"
                    :class="
                      !enoughPoints(item.points) || !hasLineManager
                        ? 'tw-text-gray-300'
                        : catalogFilter === 'off'
                        ? 'tw-text-orange-350'
                        : 'tw-text-green-500'
                    "
                  >
                    {{ item.points + " Puntos" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- tips -->
        <section
          class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-8 tw-mt-4 lg:tw-mt-8"
        >
          <p class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl">
            Tips de
            {{ catalogFilter === "off" ? "Tiempo +OFF" : "Flex work" }}
          </p>
          <div
            class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-6"
          >
            <div v-for="(tip, idx) in filteredTips" :key="idx">
              <div class="tw-grid tw-grid-cols-3 tw-h-full">
                <div
                  class="tw-col-span-1 tw-rounded-tl-lg lg:tw-rounded-tl-xl tw-rounded-bl-lg lg:tw-rounded-bl-xl tw-overflow-hidden"
                >
                  <img
                    :src="tip.image"
                    alt=""
                    class="tw-w-full tw-h-full tw-object-cover"
                  />
                </div>
                <div
                  class="tw-col-span-2 tw-p-3 lg:tw-p-4 tw-grid tw-grid-rows-6 tw-gap-2 tw-border tw-border-l-0 tw-rounded-tr-lg lg:tw-rounded-tr-xl tw-rounded-br-lg lg:tw-rounded-br-xl"
                  :class="
                    catalogFilter === 'off'
                      ? 'tw-border-orange-350'
                      : 'tw-border-green-500'
                  "
                >
                  <div class="tw-row-span-2">
                    <p
                      class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-base"
                      :class="
                        catalogFilter === 'off'
                          ? 'tw-text-orange-350'
                          : 'tw-text-green-500'
                      "
                    >
                      {{ tip.title }}
                    </p>
                  </div>
                  <div class="tw-row-span-4">
                    <p
                      class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-line-clamp-5 tw-break-words"
                    >
                      {{ tip.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<style scoped>
.disclaimer {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}
</style>

