var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c(_setup.MoleculeCardHeaderBodyFooter,{attrs:{"rounded":"","rounded-size":"2.5xl","border":"","border-color":"gray-300"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(_setup.AtomText,{attrs:{"tag":"h3","font":"robotoBold","text-size-mobile":"base","text-size-desktop":"xl","text-alignment":"center"}},[_vm._v(" Subir evidencia ")])]},proxy:true},{key:"body",fn:function(){return [_c(_setup.AtomText,{attrs:{"tag":"p","font":"roboto","text-size-mobile":"xs","text-size-desktop":"sm","text-alignment":"left"}},[_vm._v(" No olvides que debes subir tu evidencia máximo dos (2) días después del evento. "),_c('br'),_vm._v(" Se habilitara la opción de subir evidencia una vez estes inscrito en el evento ")]),(_setup.fileURL || _setup.streetEvent.registered_user_support_image)?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-mt-4"},[(
            _setup.fileType === 'image' ||
            _setup.isImage(_setup.streetEvent.registered_user_support_image)
          )?_c('img',{staticClass:"tw-max-w-full tw-max-h-60 tw-rounded-lg",attrs:{"src":_setup.fileURL ||
            _setup.returnMediaURL(_setup.streetEvent.registered_user_support_image),"alt":"Preview"}}):_vm._e(),(
            _setup.fileType === 'video' ||
            _setup.isVideo(_setup.streetEvent.registered_user_support_image)
          )?_c('video',{staticClass:"tw-max-w-full tw-max-h-60",attrs:{"src":_setup.fileURL ||
            _setup.returnMediaURL(_setup.streetEvent.registered_user_support_image),"controls":""}}):_vm._e()]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c(_setup.AtomButton,{staticClass:"tw-w-full",attrs:{"bg-color":"red-350","rounded":"","disabled":!_setup.props.registered ||
          _setup.checkExactDaysPast(_setup.streetEvent.date, _setup.streetEvent.start_time, 2)},nativeOn:{"click":function($event){return _setup.submitEvidence.apply(null, arguments)}}},[_c(_setup.AtomText,{staticClass:"tw-place-self-center",attrs:{"tag":"span","font":"roboto","text-size-mobile":"sm","text-size-desktop":"base","text-color":"white","text-alignment":"center"}},[_vm._v(" "+_vm._s(_setup.checkExactDaysPast(_setup.streetEvent.date, _setup.streetEvent.start_time, 2) ? "Evento finalizado" : _setup.fileUploaded || _setup.streetEvent.registered_user_support_image ? "Cambiar evidencia" : "Subir evidencia")+" ")])],1)]},proxy:true}])}),(
      _setup.props.registered &&
      (_setup.fileUploaded || _setup.streetEvent.registered_user_support_image)
    )?_c(_setup.MoleculeCardHeaderBodyFooter,{staticClass:"tw-mt-3 lg:tw-mt-4",attrs:{"rounded":"","rounded-size":"2.5xl","border":"","border-color":"gray-300"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c(_setup.AtomText,{attrs:{"tag":"p","font":"robotoBold","text-size-mobile":"xs","text-size-desktop":"sm","text-alignment":"left"}},[_vm._v(" Horas Ganadas ")]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c(_setup.AtomText,{staticClass:"tw-w-full",attrs:{"tag":"p","font":"roboto","text-size-mobile":"xs","text-size-desktop":"sm","text-alignment":"right"}},[_vm._v(" "+_vm._s(Number(_setup.streetEvent.hours))+" "+_vm._s(_setup.streetEvent.hours > 1 ? "horas" : "hora")+" ")]),_c(_setup.AtomIcon,{staticClass:"tw-text-xl",attrs:{"icon":"icon-reloj"}})],1)],1)]},proxy:true}],null,false,881906955)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }