<script setup>
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router/composables";
import { useEventsStore } from "@/stores/eventsStore";
import { useUserStore } from "@/stores/userStore";
import { useCoreStore } from "@/stores/coreStore";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import OrganismVamosPaLaCalleActividadDetalleGrid from "@/components/organisms/OrganismVamosPaLaCalleActividadDetalleGrid.vue";
import CompGeneralDropdownIcon from "@/components/general/CompGeneralDropdownIcon.vue";

const { actGetStreetEvents, actGetStreetEventStages } = useEventsStore();
const { actGetCoreSites } = useCoreStore();
const route = useRoute();
const isLoading = ref(false);
const optionSelected = ref(null);
const currentStage = ref(null);
const { streetEvents, streetEventStages } = storeToRefs(useEventsStore());
const { user } = storeToRefs(useUserStore());
const { coreSites } = storeToRefs(useCoreStore());

const changeCoreSite = async (val) => {
  optionSelected.value = val?.id;
  await actGetStreetEvents(route.params.id, optionSelected.value);
};

onBeforeMount(async () => {
  isLoading.value = true;
  await actGetCoreSites();
  const currentYear = new Date().getFullYear();
  await actGetStreetEventStages(currentYear);
  currentStage.value = streetEventStages.value.find(
    (stage) => stage.id === parseInt(route.params.id)
  );
  console.log(streetEventStages.value);
  console.log(currentStage.value);
  optionSelected.value = user.value.core_site_id;
  await actGetStreetEvents(route.params.id, optionSelected.value);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Actividades" />
    <title-bar title="Actividades" />
    <main class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
      <section
        class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center tw-gap-3"
      >
        <atom-text
          tag="h1"
          font="robotoMedium"
          text-size-mobile="xl"
          text-size-desktop="3xl"
        >
          Actividades
        </atom-text>
        <comp-general-dropdown-icon
          v-if="!isLoading"
          :options="coreSites"
          :is-label="false"
          :fitted="false"
          class="tw-w-full lg:tw-w-1/3"
          :default-option="user.core_site_id"
          @changeOption="changeCoreSite"
        />
      </section>
      <section
        class="tw-flex tw-justify-between tw-items-center tw-gap-2 lg:tw-gap-4 tw-border tw-border-y tw-border-x-0 tw-py-3 lg:tw-py-5"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl">
          Tu progreso de {{ currentStage?.name }}
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Horas acumuladas:</span>
          {{ currentStage?.total_stage_hours_by_employee }}
        </p>
      </section>
      <organism-vamos-pa-la-calle-actividad-detalle-grid
        :events="streetEvents"
      />
    </main>
  </div>
</template>

<style scoped></style>
