<script setup>
import { computed, onMounted, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import config from "@/services/config";
import { useNavbarStore } from "@/stores/navbarStore";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";
import { useProfileStore } from "@/stores/profileStore";
import { useRouter } from "vue-router/composables";
import MainNavbarUserMobileOptions from "@/components/main-navbar/MainNavbarUserMobileOptions.vue";
import imageBgCard from "@/assets/img/icons/icon-bg-main-navbar-user.png";
import imgAvatarEmpty from "@/assets/img/demo/avatar-empty.png";
import imgAvatar from "@/assets/img/gamification/avatar.png";
import { storeToRefs } from "pinia";

const navbarStore = useNavbarStore();
const authStore = useAuthStore();
const userStore = useUserStore();
const router = useRouter();
const target = ref(null);
const uriResources = config.beerOfficeURL;
const { user } = storeToRefs(userStore);
const { actGetProfileData } = useProfileStore();
const { userProfileData } = storeToRefs(useProfileStore());
const randomAvatar = Math.random();

const closeModalUserCard = () => {
  navbarStore.changeUserModalOpen(false);
  navbarStore.showSupportButton();
};

const gotoProfile = () => {
  closeModalUserCard();
  router.push({ name: "Profile" });
};

const closeSesion = async () => {
  closeModalUserCard();
  await authStore.actAuthLogout().then(() => {
    router.push({ name: "Login" });
  });
};

const chooseAvatar = computed(() => {
  if (user?.value.is_predefined_avatar === 1) {
    return user?.value.predefined_avatar_image;
  } else {
    return user?.value.custom_avatar_image;
  }
});

const userAvatar = computed(() => {
  if (
    user?.value.custom_avatar_image != null ||
    user?.value.predefined_avatar_image != null
  ) {
    return uriResources + "/" + chooseAvatar.value + "?v=" + randomAvatar;
  } else {
    return imgAvatar;
  }
});

const userImage = computed(() => {
  if (userProfileData.value.prof_picture) {
    return uriResources + "/" + userProfileData.value.prof_picture;
  } else if (user.value.prof_picture != null) {
    return uriResources + "/" + user.value.prof_picture;
  } else {
    return imgAvatarEmpty;
  }
});

onClickOutside(target, () => {
  closeModalUserCard();
});

onMounted(async () => {
  await actGetProfileData();
});
</script>
<template>
  <div id="inner-user" class="tw-pt-3 tw-pr-3" ref="target">
    <div
      class="tw-w-80 tw-bg-red-50 tw-rounded-tr-2xl tw-rounded-tl-2xl md:tw-rounded-br-2xl md:tw-rounded-bl-2xl tw-p-4 tw-bg-no-repeat tw-bg-left-bottom"
      :style="'background-image: url(' + imageBgCard + ')'"
    >
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <img
          src="@/assets/img/icons/icon-1-main-navbar.png"
          class="tw-w-16 tw-h-auto"
          alt="icon navbar"
        />
        <i
          class="icon-cerrar-simple tw-text-red-400 tw-text-xl tw-cursor-pointer"
          @click="closeModalUserCard"
        ></i>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-gap-3">
        <div class="tw-col-span-4 md:tw-pt-2">
          <div
            class="tw-border-4 tw-border-solid tw-border-yellow-400 tw-rounded-full tw-bg-no-repeat tw-bg-center"
          >
            <img
              :src="user.preferred_image === 'photo' ? userImage : userAvatar"
              class="tw-object-cover tw-w-20 tw-h-20 tw-rounded-full tw-max-h-20"
              alt="image user"
            />
          </div>
        </div>
        <div class="tw-col-span-8">
          <p
            class="tw-mb-3 tw-text-black tw-text-base tw-leading-4 tw-font-bold tw-font-robotoBold"
          >
            {{ userStore.user?.name }}
          </p>
          <p
            class="tw-mb-1 tw-text-xs tw-text-black tw-font-bold tw-font-robotoBold"
          >
            Usuario: {{ userStore.user?.prof_profile?.name }}
          </p>
          <p class="tw-mb-0 tw-text-xs tw-text-black">
            ID: {{ userStore.user?.id_sharp }}
          </p>
        </div>
      </div>
      <div class="tw-flex tw-justify-end tw-mt-0 tw-gap-2">
        <button
          @click="gotoProfile"
          class="tw-border tw-border-solid tw-border-red-50 tw-bg-red-400 tw-rounded-2xl tw-text-white tw-text-center tw-text-xxs tw-py-2 tw-px-4 hover:tw-bg-red-600 hover:tw-border-red-600 tw-cursor-pointer tw-grow-0 tw-w-24"
        >
          Tu perfil
        </button>
        <button
          @click="closeSesion"
          class="tw-border tw-border-solid tw-border-red-400 tw-text-red-350 tw-text-center tw-text-xxs tw-bg-red-50 tw-rounded-2xl tw-py-2 tw-px-4 hover:tw-text-red-400 tw-w-24 tw-flex tw-items-center tw-justify-center"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
    <MainNavbarUserMobileOptions />
  </div>
</template>
