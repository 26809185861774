import config from "@/services/config";

export const returnMediaURL = (image) => {
  const protocolRegex = /^(http?|https?):\/\//i;
  if (protocolRegex.test(image)) {
    return image;
  } else if (image.includes("assets")) {
    return require(`@/${image}`);
  }
  return `${config.beerOfficeURL}/${image}`;
};

// function to return the icon URL, check if its a path or just a string
export const returnIconURL = (icon) => {
  if (icon.includes("assets")) {
    return require(`@/${icon}`);
  } else if (icon.includes("storage")) {
    return `${config.beerOfficeURL}/${icon}`;
  } else {
    return icon;
  }
};

export const returnTypeOfIcon = (icon) => {
  const protocolRegex = /^(http?|https?):\/\//i;
  if (icon.includes("assets")) {
    return "local";
  } else if (icon.includes("storage")) {
    return "server";
  } else if (protocolRegex.test(icon)) {
    return "url";
  } else {
    return "string";
  }
};

export const isImage = (url) => {
  return /\.(jpg|jpeg|png|gif)$/i.test(url);
};

export const isVideo = (url) => {
  return /\.(mp4|webm|ogg)$/i.test(url);
};
