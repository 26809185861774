<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router/composables";
import OrganismButtonsRow from "@/components/organisms/OrganismButtonsRow.vue";

const route = useRoute();
const buttons = ref([
  {
    id: 0,
    icon: "icon-home",
    text: "Conoce la iniciativa",
    path: "/vamospalacalle",
  },
  {
    id: 1,
    icon: "icon-eventos",
    text: "Actividades",
    path: "/vamospalacalle/actividades",
  },
  {
    id: 2,
    icon: "icon-galeria",
    text: "Galería",
    path: "/vamospalacalle/galeria",
  },
  {
    id: 3,
    icon: "icon-ranking-eventos",
    text: "Ranking",
    path: "/vamospalacalle/ranking",
  },
]);

const selected = computed(() => {
  const normalizePath = (path) => path.replace(/\/$/, "").toLowerCase();
  const button = buttons.value.find((button) => {
    return (
      normalizePath(button.path) === normalizePath(route.path.toLowerCase())
    );
  });
  return button ? button.id : 0;
});
</script>

<template>
  <organism-buttons-row :buttons="buttons" :selected="selected" />
</template>
