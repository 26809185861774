<script setup>
import { computed } from "vue";

const emit = defineEmits(["button-click"]);

const props = defineProps({
  bgColor: {
    type: String,
    default: "transparent",
  },
  selectedBgColor: {
    type: String,
    default: "red-350", // Color de fondo cuando está seleccionado
  },
  border: {
    type: Boolean,
    default: false,
  },
  borderColor: {
    type: String,
    default: "black",
  },
  borderColorSelected: {
    type: String,
    default: "red-350",
  },
  textColor: {
    type: String,
    default: "black",
  },
  selectedTextColor: {
    type: String,
    default: "white", // Color de texto cuando está seleccionado
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  justify: {
    type: String,
    default: "center",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const computedClass = computed(() => {
  const bgColor = props.isSelected ? props.selectedBgColor : props.bgColor;
  const textColor = props.isSelected
    ? props.selectedTextColor
    : props.textColor;
  const borderColor = props.isSelected
    ? props.borderColorSelected
    : props.borderColor;

  return [
    `tw-bg-${bgColor}`,
    props.border ? `tw-border tw-border-${borderColor}` : "tw-border-none",
    props.rounded ? "tw-rounded-xl lg:tw-rounded-2xl " : "",
    `tw-text-${textColor}`,
    `tw-justify-${props.justify}`,
    !props.disabled
      ? `hover:tw-bg-${props.selectedBgColor} hover:tw-text-${props.selectedTextColor} hover:tw-border-${props.borderColorSelected} hover:tw-opacity-80`
      : "",
  ];
});
</script>

<template>
  <button
    :class="computedClass"
    class="tw-py-2 tw-px-3 tw-text-center tw-cursor-pointer tw-opacity-100 tw-flex tw-items-center tw-gap-2"
    @click="emit('button-click')"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>

<style scoped lang="postcss">
button {
  &:disabled {
    @apply tw-bg-gray-300 tw-cursor-not-allowed tw-border-none;
  }
}
</style>
