<script setup>
import { useRouter } from "vue-router/composables";
import MoleculeButtonIcon from "@/components/molecules/MoleculeButtonIcon.vue";

const router = useRouter();
const props = defineProps({
  buttons: {
    type: Array,
    required: true,
  },
  selected: {
    type: Number,
    default: 0,
  },
});
</script>

<template>
  <div class="tw-grid tw-gap-2 tw-grid-cols-2 lg:tw-grid-cols-4">
    <molecule-button-icon
      v-for="button in buttons"
      :key="button.id"
      :icon="button.icon"
      :text="button.text"
      :isSelected="selected === button.id"
      @button-click="router.push(button.path)"
      button-justify="left"
      class="tw-flex-1"
    />
  </div>
</template>

<style scoped></style>
