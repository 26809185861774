<script setup>
import { useEventsStore } from "@/stores/eventsStore";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import OrganismVamosPaLaCalleButtonsRow from "@/components/organisms/OrganismVamosPaLaCalleButtonsRow.vue";
import MoleculeVamosPaLaCalleRanking from "@/components/molecules/MoleculeVamosPaLaCalleRanking.vue";
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";

const { actGetStreetEventRanking } = useEventsStore();
const { user } = storeToRefs(useUserStore());

const generalRanking = ref([]);
const rankingVP = ref([]);

const sortedRankingVP = ref([]);

const sortedRankingGeneral = ref([]);

onBeforeMount(async () => {
  generalRanking.value = await actGetStreetEventRanking(2025);
  rankingVP.value = await actGetStreetEventRanking(2025, {
    core_vp_id: user?.value.core_vp_id,
  });

  sortedRankingVP.value = rankingVP.value.sort(
    (a, b) => b.total_hours - a.total_hours
  );

  sortedRankingGeneral.value = generalRanking.value.sort(
    (a, b) => b.total_hours - a.total_hours
  );
});
</script>

<template>
  <div>
    <mobile-bar title="Ranking" />
    <title-bar title="Ranking" />
    <main class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
      <organism-vamos-pa-la-calle-buttons-row />
      <section
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-8 tw-mt-4"
      >
        <div class="tw-flex tw-justify-center lg:tw-justify-end tw-items-start">
          <molecule-vamos-pa-la-calle-ranking
            :ranking-title="'Ranking ' + user.core_vp.name"
            :ranking="sortedRankingVP"
          />
        </div>
        <div
          class="tw-flex tw-justify-center lg:tw-justify-start tw-items-start"
        >
          <molecule-vamos-pa-la-calle-ranking
            ranking-title="Ranking General"
            :ranking="sortedRankingGeneral"
          />
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped lang="postcss">
.hover-container:hover p:last-child {
  @apply tw-text-red-350;
}
</style>
