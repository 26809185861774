<script setup>
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import OrganismVamosPaLaCalleButtonsRow from "@/components/organisms/OrganismVamosPaLaCalleButtonsRow.vue";
import { useEventsStore } from "@/stores/eventsStore";
import { onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import { capitalizeEachWord } from "@/helpers/textHelpers";
import CompGeneralDropdownIcon from "@/components/general/CompGeneralDropdownIcon.vue";
import MoleculeCardImageTextTitle from "@/components/molecules/MoleculeCardImageTextTitle.vue";
import AtomText from "@/components/atoms/AtomText.vue";

const { actGetStreetEventStages, actGetStreetEventsGallery } = useEventsStore();
const { streetEventStages, streetEventGallery } = storeToRefs(useEventsStore());
const isLoading = ref(false);

const changeStage = async (val) => {
  await actGetStreetEventsGallery(val.id);
};

const randomGalleryFour = (gallery) => {
  if (gallery.length <= 4) return gallery;
  const randomGallery = [];
  const galleryCopy = [...gallery]; // Create a copy of the original array

  for (let i = 0; i < 4; i++) {
    if (galleryCopy.length === 0) break; // Break if there are no more elements to select
    const randomIndex = Math.floor(Math.random() * galleryCopy.length);
    randomGallery.push(galleryCopy[randomIndex]);
    galleryCopy.splice(randomIndex, 1); // Remove the selected element
  }

  return randomGallery;
};

onBeforeMount(async () => {
  isLoading.value = true;
  const currentYear = new Date().getFullYear();
  await actGetStreetEventStages(currentYear);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Galería" />
    <title-bar title="Galería" />
    <main class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
      <organism-vamos-pa-la-calle-buttons-row />
      <comp-general-dropdown-icon
        v-if="!isLoading"
        :options="streetEventStages"
        :is-label="false"
        :fitted="false"
        class="tw-w-full lg:tw-w-1/3"
        @changeOption="changeStage"
      />
      <section v-for="event in streetEventGallery" :key="event.id">
        <template v-if="event.gallery.length > 0">
          <atom-text
            tag="h2"
            text-size-mobile="lg"
            text-size-desktop="xl"
            font="robotoBold"
          >
            {{ event.name }}
          </atom-text>
          <div
            class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 tw-mt-4"
          >
            <molecule-card-image-text-title
              v-for="image in randomGalleryFour(event.gallery)"
              :key="image.id"
              :src="image.image"
              :description="capitalizeEachWord(image.name)"
              :button="false"
            />
          </div>
        </template>
      </section>
    </main>
  </div>
</template>

<style scoped></style>
