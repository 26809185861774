<script setup>
import { pushLink } from "@/helpers/linkHelpers";
import AtomMedia from "@/components/atoms/AtomMedia.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  title: {
    type: String,
  },
  description: {
    type: String,
    required: true,
  },
  button: {
    type: Boolean,
    required: true,
    default: false,
  },
  buttonText: {
    type: String,
  },
  link: {
    type: String,
    required: false,
  },
  rectangular: {
    type: Boolean,
    default: false,
  },
});

const handleClick = () => {
  pushLink(props.link);
};
</script>

<template>
  <div
    class="tw-grid tw-grid-rows-rows_3_fit_first_and_last tw-gap-3 lg:tw-gap-4 tw-p-2 lg:tw-p-3 tw-border tw-border-gray-300 tw-rounded-2xl lg:tw-rounded-2.5xl tw-h-full"
  >
    <atom-media
      rounded
      type="image"
      :src="props.src"
      class="tw-w-full tw-h-full"
      :class="
        props.rectangular
          ? 'tw-aspect-w-16 tw-aspect-h-9'
          : 'tw-aspect-w-1 tw-aspect-h-1'
      "
      fill
    />
    <div id="title_and_description">
      <atom-text
        tag="h3"
        font="robotoBold"
        text-size-mobile="xl"
        text-size-desktop="xl"
        text-color="black"
      >
        {{ props.title }}
      </atom-text>
      <atom-text
        tag="p"
        font="roboto"
        text-size-mobile="sm"
        text-size-desktop="base"
        text-color="black"
      >
        {{ props.description }}
      </atom-text>
    </div>
    <atom-button
      bg-color="red-350"
      rounded
      v-if="props.button"
      @button-click="props.link ? handleClick() : null"
    >
      <atom-text
        tag="span"
        font="roboto"
        text-size-mobile="sm"
        text-size-desktop="base"
        text-color="white"
      >
        {{ props.buttonText }}
      </atom-text>
    </atom-button>
  </div>
</template>

<style scoped></style>
