var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-fixed",class:_setup.props.isShowModal && _setup.props.backdrop && _setup.props.overall
      ? 'active tw-h-screen tw-w-full tw-z-100'
      : _setup.props.isShowModal && _setup.props.backdrop && !_setup.props.overall
      ? 'active tw-h-screen tw-w-full tw-z-40'
      : _setup.props.isShowModal && !_setup.props.backdrop
      ? 'tw-z-100'
      : 'tw-h-0 tw-w-full',style:(_setup.props.isShowModal && !_setup.props.backdrop
      ? 'top: ' +
        _setup.calculateCoordinatesY() +
        'px; left: ' +
        _setup.calculateCoordinatesX() +
        'px;'
      : _setup.props.isShowModal && _setup.props.backdrop
      ? 'background-color: rgba(0, 0, 0, ' + _setup.props.backdropOpacity + ');'
      : ''),attrs:{"id":"modal-general"},on:{"mouseleave":function($event){_setup.props.tiny ? _setup.handleClose() : ''}}},[_c('div',{staticClass:"modal-container tw-mx-auto",class:_setup.props.isShowModal && !_setup.props.tiny
        ? 'active tw-transform tw-translate-y-4 md:tw-translate-y-8 lg:tw-translate-y-10'
        : 'active',style:(`width: ${_setup.widthContainer}; max-height: ${_setup.maxHeightContainer} ${
      _vm.heightFit ? 'height: fit-content;' : `height: auto;`
    }`),attrs:{"id":_vm.modalName}},[_c('div',{ref:"target",staticClass:"inside tw-bg-white tw-h-auto tw-w-full tw-max-h-full tw-rounded-3xl tw-flex tw-flex-col tw-relative",class:[
        _setup.props.tiny
          ? 'tw-shadow-lg tw-px-3 tw-gap-x-4'
          : 'tw-relative tw-shadow-sm tw-overflow-hidden tw-gap-4',
        _setup.props.paddingX ? 'tw-px-6 md:tw-px-7 lg:tw-px-10' : '',
        _setup.props.paddingY ? 'tw-py-3 md:tw-py-4 lg:tw-py-5' : '',
      ],style:('max-height: ' + _setup.maxHeightContainer)},[_c('div',[(_vm.$slots.title)?_c('div',[_c('p',{staticClass:"tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-3xl tw-text-red-350"},[_vm._t("title")],2)]):_vm._e(),(_setup.props.closable)?_c('div',{staticClass:"tw-absolute tw-right-5 tw-top-5 md:tw-right-6 md:tw-top-6 lg:tw-right-7 lg:tw-top-7 tw-cursor-pointer tw-z-2",on:{"click":_setup.handleClose}},[_c('i',{staticClass:"icon-cerrar-simple tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-text-red-350 tw-font-black"})]):_vm._e()]),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }