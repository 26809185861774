import { render, staticRenderFns } from "./AtomImageRounded.vue?vue&type=template&id=2c621d3a&scoped=true"
import script from "./AtomImageRounded.vue?vue&type=script&setup=true&lang=js"
export * from "./AtomImageRounded.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c621d3a",
  null
  
)

export default component.exports