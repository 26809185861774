var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
      `tw-flex tw-justify-${_vm.alignment} tw-items-center`,
      _vm.rounded ? 'tw-rounded-2.5xl tw-overflow-hidden' : '',
      _vm.fill ? 'tw-w-full' : 'tw-w-fit',
      _vm.rectangular ? 'tw-aspect-[16/10]' : '',
    ].filter(Boolean)},[(_vm.type === 'image')?_c('img',{class:[
        'tw-place-self-center tw-self-center tw-content-center tw-justify-self-center',
        _vm.rounded ? 'tw-rounded-2xl lg:tw-rounded-3xl' : '',
        _vm.fill ? ' tw-h-full tw-object-cover' : 'tw-max-w-full tw-max-h-full',
        _vm.customWidth ? `tw-${_vm.customWidth}` : 'tw-w-full',
        _vm.cover ? 'tw-w-full tw-h-full tw-object-cover' : '',
      ].filter(Boolean),attrs:{"src":_setup.returnMediaURL(_vm.src),"alt":"media"}}):_c('video',{ref:"videoRef",staticClass:"tw-w-full tw-h-auto",class:{ 'tw-rounded-2xl lg:tw-rounded-3xl': _vm.rounded },attrs:{"src":`${_setup.returnMediaURL(_vm.src)}#t=0.1`,"controls":"","playsinline":"","preload":"metadata"},on:{"play":function($event){_setup.playing = true},"pause":function($event){_setup.playing = false},"fullscreenchange":_setup.handleFullscreenChange}}),(!_setup.playing && _vm.type === 'video')?_c(_setup.AtomPlayButtonOverlay,{on:{"play":_setup.playVideo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }