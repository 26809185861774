import { render, staticRenderFns } from "./AtomButton.vue?vue&type=template&id=2174d226&scoped=true"
import script from "./AtomButton.vue?vue&type=script&setup=true&lang=js"
export * from "./AtomButton.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AtomButton.vue?vue&type=style&index=0&id=2174d226&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2174d226",
  null
  
)

export default component.exports