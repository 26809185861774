<script setup>
import AtomButton from "@/components/atoms/AtomButton.vue";
import AtomIcon from "@/components/atoms/AtomIcon.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import MoleculeCardHeaderBodyFooter from "@/components/molecules/MoleculeCardHeaderBodyFooter.vue";
import { useEventsStore } from "@/stores/eventsStore";
import { useRoute } from "vue-router/composables";
import { useImageUploader } from "@/composables/general/useImageUploader";
import { returnMediaURL, isImage, isVideo } from "@/helpers/imageHelpers";
import { storeToRefs } from "pinia";
import { checkExactDaysPast } from "@/helpers/datesHelpers";

const { uploadFile, fileType, fileUploaded, fileURL, formData } =
  useImageUploader();
const route = useRoute();
const { actPostStreetEventUploadEvidence } = useEventsStore();
const { streetEvent } = storeToRefs(useEventsStore());

const props = defineProps({
  registered: {
    type: Boolean,
    required: true,
  },
});

async function submitEvidence() {
  fileType.value = "image";
  try {
    const { success, fileName } = await uploadFile(
      fileType.value,
      "image_support"
    );
    if (success) {
      console.log("Imagen cargada con éxito:", fileName);
      fileUploaded.value = true;
    }
  } catch (error) {
    console.error("Error al cargar la imagen:", error.message);
    fileUploaded.value = false;
  }
  const response = await actPostStreetEventUploadEvidence(
    route.params.eventId,
    formData
  );
  if (response.status && response.status !== 200) {
    alert("Error al subir la evidencia, intenta nuevamente");
  }
}
</script>

<template>
  <section>
    <molecule-card-header-body-footer
      rounded
      rounded-size="2.5xl"
      border
      border-color="gray-300"
    >
      <template #header>
        <atom-text
          tag="h3"
          font="robotoBold"
          text-size-mobile="base"
          text-size-desktop="xl"
          text-alignment="center"
        >
          Subir evidencia
        </atom-text>
      </template>
      <template #body>
        <atom-text
          tag="p"
          font="roboto"
          text-size-mobile="xs"
          text-size-desktop="sm"
          text-alignment="left"
        >
          No olvides que debes subir tu evidencia máximo dos (2) días después
          del evento. <br />
          Se habilitara la opción de subir evidencia una vez estes inscrito en
          el evento
        </atom-text>
        <div
          v-if="fileURL || streetEvent.registered_user_support_image"
          class="tw-flex tw-justify-center tw-items-center tw-mt-4"
        >
          <img
            v-if="
              fileType === 'image' ||
              isImage(streetEvent.registered_user_support_image)
            "
            :src="
              fileURL ||
              returnMediaURL(streetEvent.registered_user_support_image)
            "
            alt="Preview"
            class="tw-max-w-full tw-max-h-60 tw-rounded-lg"
          />
          <video
            v-if="
              fileType === 'video' ||
              isVideo(streetEvent.registered_user_support_image)
            "
            :src="
              fileURL ||
              returnMediaURL(streetEvent.registered_user_support_image)
            "
            controls
            class="tw-max-w-full tw-max-h-60"
          ></video>
        </div>
      </template>
      <template #footer>
        <atom-button
          bg-color="red-350"
          rounded
          class="tw-w-full"
          :disabled="
            !props.registered ||
            checkExactDaysPast(streetEvent.date, streetEvent.start_time, 2)
          "
          @click.native="submitEvidence"
        >
          <atom-text
            tag="span"
            font="roboto"
            text-size-mobile="sm"
            text-size-desktop="base"
            text-color="white"
            text-alignment="center"
            class="tw-place-self-center"
          >
            {{
              checkExactDaysPast(streetEvent.date, streetEvent.start_time, 2)
                ? "Evento finalizado"
                : fileUploaded || streetEvent.registered_user_support_image
                ? "Cambiar evidencia"
                : "Subir evidencia"
            }}
          </atom-text>
        </atom-button>
      </template>
    </molecule-card-header-body-footer>
    <molecule-card-header-body-footer
      rounded
      rounded-size="2.5xl"
      border
      border-color="gray-300"
      class="tw-mt-3 lg:tw-mt-4"
      v-if="
        props.registered &&
        (fileUploaded || streetEvent.registered_user_support_image)
      "
    >
      <template #body>
        <div class="tw-flex tw-justify-between tw-items-center">
          <atom-text
            tag="p"
            font="robotoBold"
            text-size-mobile="xs"
            text-size-desktop="sm"
            text-alignment="left"
          >
            Horas Ganadas
          </atom-text>
          <div class="tw-flex tw-items-center tw-gap-2">
            <atom-text
              tag="p"
              font="roboto"
              text-size-mobile="xs"
              text-size-desktop="sm"
              text-alignment="right"
              class="tw-w-full"
            >
              {{ Number(streetEvent.hours) }}
              {{ streetEvent.hours > 1 ? "horas" : "hora" }}
            </atom-text>
            <atom-icon icon="icon-reloj" class="tw-text-xl" />
          </div>
        </div>
      </template>
    </molecule-card-header-body-footer>
  </section>
</template>

<style scoped></style>
