<script setup>
import { computed, ref, watchEffect } from "vue";
import { useUserStore } from "@/stores/userStore";
import AtomText from "@/components/atoms/AtomText.vue";
import AtomImageRounded from "@/components/atoms/AtomImageRounded.vue";
import { storeToRefs } from "pinia";

const props = defineProps({
  rankingTitle: {
    type: String,
  },
  ranking: {
    type: Array,
  },
});

const { user } = storeToRefs(useUserStore());
const firstTenRanking = ref([]);

const userRankingInfo = computed(() => {
  const userIndex = props.ranking.findIndex(
    (item) => item.employee_id === user.value.id
  );

  if (userIndex <= -1) return null;

  return {
    ...props.ranking[userIndex],
    score: userIndex + 1,
  };
});

const processedRanking = computed(() => {
  const topTen = props.ranking.slice(0, 10).map((item, index) => ({
    ...item,
    score: index + 1,
  }));

  if (
    userRankingInfo.value &&
    !topTen.some((item) => item.employee_id === user.value.id)
  ) {
    topTen.pop();
    topTen.push(userRankingInfo.value);
  }

  return topTen;
});

watchEffect(() => {
  firstTenRanking.value = processedRanking.value;
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-gap-4 tw-w-10/12 tw-rounded-2.5xl tw-border tw-border-gray-300 tw-p-4"
  >
    <atom-text
      tag="h2"
      font="robotoMedium"
      text-size-mobile="xl"
      text-size-desktop="2xl"
      text-alignment="center"
    >
      {{ props.rankingTitle }}
    </atom-text>
    <div
      class="tw-rounded-2.5xl tw-border tw-border-gray-300 tw-p-2 tw-bg-gray-200"
    >
      <div
        class="tw-grid tw-grid-cols-col3_fit_first_and_last tw-gap-3 tw-px-3 tw-py-2 tw-rounded-2.5xl"
        v-for="item in firstTenRanking"
        :key="item.id"
        :class="{
          active: item.employee_id === user.id,
        }"
      >
        <div
          class="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-w-fit"
        >
          <atom-text
            tag="p"
            font="roboto"
            text-size-mobile="sm"
            text-size-desktop="base"
          >
            {{ item.score }}
          </atom-text>
          <atom-image-rounded
            alt=""
            src="assets/img/demo/avatar-empty.png"
            class="tw-max-w-16"
            cover
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-center">
          <atom-text
            tag="h3"
            font="robotoMedium"
            text-size-mobile="sm"
            text-size-desktop="base"
            class="tw-line-clamp-1"
          >
            {{ item.employee_name }}
          </atom-text>
          <atom-text
            tag="span"
            font="roboto"
            text-color="gray-400"
            text-size-mobile="xs"
            text-size-desktop="sm"
          >
            {{ item.site_name }}
          </atom-text>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center tw-w-fit">
          <atom-text
            tag="p"
            font="robotoBold"
            text-size-mobile="base"
            text-size-desktop="lg"
          >
            {{ item.total_hours }}
          </atom-text>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.active {
  @apply tw-bg-white tw-shadow-md tw-shadow-gray-350;
}

.active p:last-child {
  @apply tw-text-red-350;
}
</style>
