import { defineStore } from "pinia";
import { ref } from "vue";
import AxiosBeerOffice from "@/services/axiosBeerOffice";

const uri = {
  eventsUri: "/events",
  eventUri: "/calendarEvents",
  streetEventsUri: "/streetEvents",
  streetEventStagesUri: "/streetEventStages",
  streetEventRankingUri: "/streetRanking",
  employeeStreetEventsUri: "/employeeStreetEvents",
};

export const useEventsStore = defineStore("events", () => {
  const events = ref([]);
  const event = ref({});
  const streetEvents = ref([]);
  const streetEvent = ref({});
  const streetEventStages = ref([]);
  const streetEventGallery = ref([]);
  const employeeStreetEvents = ref([]);

  async function actGetEvents() {
    const response = await AxiosBeerOffice.get(uri.eventsUri, true, {});
    if (response.error) return response.error;
    events.value = response.events;
    return true;
  }

  async function actGetEventById(id) {
    const response = await AxiosBeerOffice.get(
      uri.eventUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    event.value = response.data;
    return true;
  }

  async function actGetStreetEventsGallery(id) {
    const response = await AxiosBeerOffice.get(
      uri.streetEventStagesUri + "/" + id + "/gallery",
      true,
      {}
    );
    if (response.error) return response.error;
    streetEventGallery.value = response.events;
    return true;
  }

  async function actPostEventRegister(id) {
    const response = await AxiosBeerOffice.post(
      `${uri.eventsUri}/${id}/register`,
      true,
      {}
    );
    if (response.error) return response.error;
    return response;
  }

  async function actGetStreetEvents(id, core_site_id) {
    const response = await AxiosBeerOffice.get(
      `${uri.streetEventsUri}?stage=${id}&core_site_id=${core_site_id}`,
      true,
      {}
    );
    if (response.error) return response.error;
    streetEvents.value = response.events;
    return true;
  }

  async function actGetStreetEventById(id) {
    const response = await AxiosBeerOffice.get(
      uri.streetEventsUri + "/" + id,
      true,
      {}
    );
    if (response.error) return response.error;
    streetEvent.value = response.data;
    return true;
  }

  async function actGetStreetEventStages(year) {
    const response = await AxiosBeerOffice.get(
      `${uri.streetEventStagesUri}?year=${year}`,
      true,
      {}
    );
    if (response.error) return response.error;
    streetEventStages.value = response.stages;
    return true;
  }

  async function actGetStreetEventRanking(year, params = {}) {
    let query = `year=${year}`;
    for (const [key, value] of Object.entries(params)) {
      query += `&${key}=${value}`;
    }

    const response = await AxiosBeerOffice.get(
      `${uri.streetEventRankingUri}?${query}`,
      true,
      {}
    );
    if (response.error) return response.error;
    return response;
  }

  async function actGetEmployeeStreetEvents() {
    const response = await AxiosBeerOffice.get(
      uri.employeeStreetEventsUri,
      true,
      {}
    );
    if (response.error) return response.error;
    employeeStreetEvents.value = response.events;
    return true;
  }

  async function actPostStreetEventsRegister(id) {
    const response = await AxiosBeerOffice.post(
      `${uri.streetEventsUri}/${id}/register`,
      true,
      {}
    );
    if (response.error) return response.error;
    return response;
  }

  async function actPostStreetEventUploadEvidence(id, data) {
    const response = await AxiosBeerOffice.post(
      `${uri.streetEventsUri}/${id}/upload-support`,
      true,
      data,
      true
    );
    console.log(response);
    if (response.error) return response.error;
    return response;
  }

  async function actPostCancelEmployeeStreetEvent(id, user_id) {
    const response = await AxiosBeerOffice.post(
      `${uri.employeeStreetEventsUri}/${id}/cancel`,
      true,
      {
        calendar_event_employee_id: user_id,
      }
    );
    if (response.error) return response.error;
    return response;
  }

  return {
    events,
    event,
    streetEvents,
    streetEvent,
    streetEventStages,
    streetEventGallery,
    employeeStreetEvents,
    actGetEvents,
    actGetEventById,
    actPostEventRegister,
    actGetStreetEvents,
    actGetStreetEventById,
    actGetStreetEventStages,
    actGetStreetEventRanking,
    actGetEmployeeStreetEvents,
    actPostStreetEventsRegister,
    actPostStreetEventUploadEvidence,
    actGetStreetEventsGallery,
    actPostCancelEmployeeStreetEvent,
  };
});
