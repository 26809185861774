<script setup>
import config from "@/services/config";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import imgAvatar from "@/assets/img/gamification/avatar.png";
import imgAvatarEmpty from "@/assets/img/demo/avatar-empty.png";
import { useProfileStore } from "@/stores/profileStore";

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const uriResources = config.beerOfficeURL;
const randomAvatar = Math.random();
const { userProfileData } = storeToRefs(useProfileStore());

const chooseAvatar = computed(() => {
  if (
    userProfileData.value.length > 0 &&
    userProfileData.value.prof_picture != null
  ) {
    return uriResources + "/" + userProfileData.value.prof_picture;
  } else if (user.value.prof_picture != null) {
    return uriResources + "/" + user.value.prof_picture;
  } else {
    return imgAvatarEmpty;
  }
});

const userAvatar = computed(() => {
  if (
    user?.value.custom_avatar_image != null ||
    user?.value.predefined_avatar_image != null
  ) {
    return uriResources + "/" + chooseAvatar.value + "?v=" + randomAvatar;
  } else {
    return imgAvatar;
  }
});

const userImage = computed(() => {
  if (userProfileData.value.prof_picture != null) {
    return uriResources + "/" + userProfileData.value.prof_picture;
  } else {
    return imgAvatarEmpty;
  }
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-end tw-gap-3 lg:tw-gap-6"
  >
    <div
      class="tw-border-4 tw-border-solid tw-border-red-350 tw-rounded-full tw-bg-no-repeat tw-bg-center tw-bg-white -tw-mt-14 lg:-tw-mt-16 tw-h-20 tw-w-20 lg:tw-h-32 lg:tw-w-32 tw-mx-auto lg:tw-mx-0"
    >
      <img
        :src="
          userProfileData.preferred_image === 'photo' ? userImage : userAvatar
        "
        class="tw-object-cover tw-rounded-full tw-h-full tw-w-full"
        alt="image user"
      />
    </div>
    <p
      class="tw-m-0 tw-font-robotoBold tw-text-xl lg:tw-text-2.5xl tw-capitalize tw-text-center"
    >
      {{ userProfileData?.nickname?.toLowerCase() }}
    </p>
    <div class="tw-flex tw-items-center tw-gap-4 tw-w-fit tw-mx-auto lg:tw-m-0">
      <p class="tw-m-0 tw-font-robotoLight tw-text-xs lg:tw-text-base">
        {{
          userProfileData.gender === "male"
            ? "Hombre"
            : userProfileData.gender === "female"
            ? "Mujer"
            : "No binario"
        }}
      </p>
      <p class="tw-m-0 tw-font-robotoLight tw-text-xl">|</p>
      <p class="tw-m-0 tw-font-robotoLight tw-text-xs lg:tw-text-base">
        {{
          userProfileData.pronoun === "he"
            ? "El"
            : userProfileData.pronoun === "she"
            ? "Ella"
            : userProfileData.pronoun === "they"
            ? "Elle"
            : "N/A"
        }}
      </p>
      <p class="tw-m-0 tw-font-robotoLight tw-text-xl">|</p>
      <p
        class="tw-m-0 tw-font-robotoLight tw-capitalize tw-text-xs lg:tw-text-base"
      >
        {{ user.core_position?.name.toLowerCase() }}
      </p>
      <p class="tw-m-0 tw-font-robotoLight tw-text-xl">|</p>
      <p
        class="tw-m-0 tw-font-robotoLight tw-capitalize tw-text-xs lg:tw-text-base"
      >
        {{ user.prof_profile?.name }}
      </p>
    </div>
  </div>
</template>

<style scoped></style>
