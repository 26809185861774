<script setup>
import { ref } from "vue";
import AtomText from "@/components/atoms/AtomText.vue";
import MoleculeCardImageTextButton from "@/components/molecules/MoleculeCardImageTextButton.vue";

const cardsContent = ref([
  {
    id: 1,
    imageSrc: "assets/img/vamospalacalle/plantas_o_malterias.png",
    title: "Cervecerías o Malterías",
    description:
      "Conoce los lugares donde se hacen las polas. Nuestras Cervecerías de Tocancipá, Del Valle, Unión, Barranquilla, Tibasosa y Bucaramanga. Fábrica de Tapas, Indugral y Maltería Tropical y de Tibitó.",
    button: false,
    buttonText: "Inscribirse al evento",
    link: "https://forms.office.com/r/kbQsnayWpd",
  },
  {
    id: 2,
    imageSrc: "assets/img/vamospalacalle/visita_a_tada.png",
    title: "Visita a TaDa",
    description:
      "TaDa es nuestro mejor punto de contacto directo con nuestros consumidores. Tenemos más de 70 en la calle y allí seguro podremos extraer aprendizajes sobre rotación de inventarios, combos, descuentos, ¡y mucho más!",
    button: false,
    buttonText: "Inscribirse al evento",
    link: "https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5NoWY6EUL5l9MsaHdnLzM4WpUMkRNTVA0UjlZV080RVBMRjhTQklDWkVUMS4u",
  },
  {
    id: 3,
    imageSrc: "assets/img/vamospalacalle/salida_masiva.png",
    title: "Salida al mercado",
    description:
      "En la calle están nuestros clientes; tenderos y tenderas que nos han visto crecer, ¡y que todavía nos fían! Vamos a conocer sus historias, a mejorar sus negocios y a aprender del emocionante mercado colombiano.",
    button: false,
    buttonText: "Inscribirse al evento",
    link: "https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5NoWY6EUL5l9MsaHdnLzM4WpUOFhMNlZXQTcxQ1lPTkJGUTBWNlJBNUxOSi4u",
  },
  {
    id: 4,
    imageSrc: "assets/img/vamospalacalle/insights.png",
    title: "Insights",
    description:
      "Qué privilegio escuchar lo que los consumidores piensan de nuestros productos, experiencias y del impacto que generamos en Colombia. Vamos a escucharlos y a capturar los mejores aprendizajes.",
    button: false,
    buttonText: "Inscribirse al evento",
    link: "https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5NoWY6EUL5l9MsaHdnLzM4WpUNkxSUkJXVldFOVcyMjQxRjFVTzdMTEJMNC4u",
  },
]);
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-5">
    <atom-text
      tag="h2"
      font="robotoBold"
      text-size-mobile="xl"
      text-size-desktop="2xl"
      text-color="black"
    >
      Eventos a los que puedes asistir
    </atom-text>
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-5">
      <molecule-card-image-text-button
        v-for="(card, idx) in cardsContent"
        class="tw-w-9/12 lg:tw-w-auto tw-place-self-center"
        :key="idx"
        :src="card.imageSrc"
        :title="card.title"
        :description="card.description"
        :button="card.button"
        :button-text="card.buttonText"
        :link="card.link"
        rectangular
      />
    </div>
  </div>
</template>

<style scoped></style>
