<script setup>
import HandleModal from "@/components/modals/HandleModal.vue";
import { computed, onBeforeMount, ref } from "vue";
import CompAccordionParent from "@/components/accordions/CompAccordionParent.vue";
import CompAccordionItem from "@/components/accordions/CompAccordionItem.vue";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import CompVamosPaLaCalleEventDetail from "@/components/vamospalacalle/CompVamosPaLaCalleEventDetail.vue";
import { useEventsStore } from "@/stores/eventsStore";
import { storeToRefs } from "pinia";

const { actGetEmployeeStreetEvents } = useEventsStore();
const { employeeStreetEvents } = storeToRefs(useEventsStore());

const isLoadingEvents = ref(false);
const conditional = ref(true);

const eventsToShow = computed(() => {
  return [...employeeStreetEvents.value]
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    })
    .slice(0, 3);
});

onBeforeMount(async () => {
  isLoadingEvents.value = true;
  await actGetEmployeeStreetEvents();
  isLoadingEvents.value = false;
});
</script>

<template>
  <div v-if="!isLoadingEvents">
    <portal to="all-modals">
      <handle-modal
        :max-height="{ base: 400, md: 500, lg: 600, xl: 600 }"
        :modal-width="{ md: 400, lg: 400, xl: 400 }"
        modal-name="vamos-pa-la-calle"
        icon="icon-eventos"
        ref="handleModalRef"
      >
        <div class="tw-grid tw-auto-rows-auto tw-gap-3">
          <!-- Title -->
          <div class="tw-flex tw-justify-center">
            <p
              class="tw-font-robotoBold tw-text-lg lg:tw-text-xl tw-text-red-350"
            >
              Tus eventos
            </p>
          </div>
          <!-- No data -->
          <div v-if="!conditional" class="tw-flex tw-flex-col tw-gap-6">
            <p class="tw-m-0 tw-font-roboto tw-text-xs tw-text-center">
              No has realizado ningún evento
            </p>
            <div class="tw-aspect-w-10 tw-aspect-h-8">
              <img
                src="@/assets/img/order-status/order-no-pending-order.svg"
                alt=""
              />
            </div>
          </div>
          <!-- Accordion -->
          <comp-accordion-parent open-first always-open-one v-else>
            <comp-accordion-item
              v-for="(event, idx) in eventsToShow"
              open-first
              :id="'event_' + idx"
              :key="idx"
            >
              <template v-slot:mainInfo>
                <div class="tw-grid tw-grid-cols-col2_fit_last tw-w-full">
                  <div class="tw-grid tw-grid-rows-2">
                    <p class="tw-font-robotoBold tw-text-sm tw-m-0">
                      {{ event.calendar_event.name }}
                    </p>
                    <p
                      class="tw-font-roboto tw-text-sm tw-m-0 tw-text-gray-300"
                    >
                      {{ formatDateYYYYMMDDLong(event.calendar_event.date) }}
                    </p>
                  </div>
                  <div class="tw-h-full tw-flex tw-items-center">
                    <div class="tw-status-bubble tw-bg-red-400">
                      <p class="tw-m-0 tw-text-white">
                        {{
                          event.status === "active" ? "Registrado" : "Cancelado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:secondaryInfo>
                <comp-vamos-pa-la-calle-event-detail :event="event" />
              </template>
            </comp-accordion-item>
          </comp-accordion-parent>
        </div>
      </handle-modal>
    </portal>
  </div>
</template>

<style scoped></style>
