<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";
import TitleBar from "@/components/general/TitleBar.vue";
import MobileBar from "@/components/general/MobileBar.vue";
import AtomText from "@/components/atoms/AtomText.vue";
import OrganismVamosPaLaCalleActividadesGrid from "@/components/organisms/OrganismVamosPaLaCalleActividadesGrid.vue";
import { useEventsStore } from "@/stores/eventsStore";
import { useUserStore } from "@/stores/userStore";
import OrganismVamosPaLaCalleButtonsRow from "@/components/organisms/OrganismVamosPaLaCalleButtonsRow.vue";

const { actGetStreetEventStages, actGetStreetEventRanking } = useEventsStore();
const { streetEventStages } = storeToRefs(useEventsStore());
const { user } = storeToRefs(useUserStore());
const isLoading = ref(false);
const streetEventRanking = ref([]);

const userRanking = computed(() =>
  streetEventRanking.value?.find(
    (ranking) => ranking.employee_id === user.value.id
  )
);

const userIndex = computed(() =>
  streetEventRanking.value?.findIndex(
    (ranking) => ranking.employee_id === user.value.id
  )
);

onBeforeMount(async () => {
  isLoading.value = true;
  const currentYear = new Date().getFullYear();
  await actGetStreetEventStages(currentYear);
  streetEventRanking.value = await actGetStreetEventRanking(currentYear);
  isLoading.value = false;
});
</script>

<template>
  <div>
    <mobile-bar title="Actividades" />
    <title-bar title="Actividades" />
    <main class="tw-container-general tw-flex tw-flex-col tw-gap-3 lg:tw-gap-4">
      <organism-vamos-pa-la-calle-buttons-row />
      <atom-text
        tag="h1"
        font="robotoMedium"
        text-size-mobile="xl"
        text-size-desktop="3xl"
        class="tw-mt-5"
      >
        Actividades Eventos {{ new Date().getFullYear() }}
      </atom-text>
      <section
        class="tw-flex tw-justify-between tw-items-center tw-gap-2 lg:tw-gap-4 tw-border tw-border-y tw-border-x-0 tw-py-3 lg:tw-py-5"
      >
        <p class="tw-m-0 tw-font-robotoBold tw-text-base lg:tw-text-xl">
          Tu progreso general
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Ranking:</span>
          {{ userRanking?.site_name }}
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Horas acumuladas:</span>
          {{ userRanking?.total_hours }}
        </p>
        <p class="tw-m-0 tw-font-roboto tw-text-base lg:tw-text-xl">
          <span class="tw-font-robotoBold">Puesto:</span>
          {{ userIndex + 1 }}°
        </p>
      </section>
      <p class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base">
        "Vamos pa la calle" es una iniciativa que te invita a vivir una
        experiencia única en los puntos de producción y distribución de nuestras
        cervezas. A través de nuestra plataforma puedes inscribirte a eventos
        con fecha y hora específicas para conocer de cerca los procesos de
        nuestros productos. Es una oportunidad perfecta para aprender, disfrutar
        y conectarte con tus compañeros. ¡Te esperamos para que vivas esta
        experiencia con nosotros!
      </p>
      <organism-vamos-pa-la-calle-actividades-grid
        v-if="!isLoading"
        :stages="streetEventStages"
      />
    </main>
  </div>
</template>

<style scoped></style>
