<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router/composables";
import { useEventsStore } from "@/stores/eventsStore";
import { formatDateYYYYMMDDLong } from "@/helpers/datesHelpers";
import AtomButton from "@/components/atoms/AtomButton.vue";
import AtomText from "@/components/atoms/AtomText.vue";

const cancel = ref(false);
const router = useRouter();

const props = defineProps({
  event: {
    type: Object,
    default: () => {},
  },
});

const { actGetEmployeeStreetEvents, actPostCancelEmployeeStreetEvent } =
  useEventsStore();

const cancelRequest = async () => {
  if (!cancel.value) {
    cancel.value = true;
  } else {
    await actPostCancelEmployeeStreetEvent(
      props.event.id,
      props.event.calendar_event_id
    );
    await actGetEmployeeStreetEvents();
    cancel.value = false;
  }
};

const goToEvent = (stage_id, event_id) => {
  router.push(`/vamosPaLaCalle/actividades/${stage_id}/evento/${event_id}`);
};

const goBackCancel = () => {
  cancel.value = false;
};
</script>
<template>
  <div>
    <div v-if="!cancel">
      <div class="tw-flex tw-flex-col tw-gap-5 tw-mt-4">
        <div class="tw-flex tw-flex-col tw-gap-3">
          <div>
            <p class="tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0">
              {{
                `Te inscribiste en el evento del día ${formatDateYYYYMMDDLong(
                  props.event.calendar_event.date
                )}`
              }}
            </p>
          </div>
        </div>

        <div class="tw-grid tw-grid-cols-2 tw-gap-4">
          <atom-button
            border
            bg-color="white"
            rounded
            class="tw-w-full"
            :disabled="props.event.status !== 'active'"
            @button-click="
              props.event.status === 'active' ? cancelRequest() : null
            "
          >
            <atom-text
              tag="span"
              font="roboto"
              text-size-mobile="xs"
              text-size-desktop="sm"
              text-alignment="center"
              text-color=""
              class="tw-place-self-center"
            >
              {{
                props.event.status === "active"
                  ? "Cancelar solicitud"
                  : "Registro cancelado"
              }}
            </atom-text>
          </atom-button>
          <atom-button
            border
            bg-color="black"
            text-color="white"
            rounded
            class="tw-w-full"
            @button-click="goToEvent(event.street_event_stage_id, event.id)"
          >
            <atom-text
              tag="span"
              font="roboto"
              text-size-mobile="xs"
              text-size-desktop="sm"
              text-alignment="center"
              text-color=""
              class="tw-place-self-center"
            >
              Ver más información
            </atom-text>
          </atom-button>
        </div>
      </div>
    </div>
    <div v-else class="tw-flex tw-flex-col tw-gap-5">
      <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-3">
        <div class="tw-col-span-1">
          <div class="tw-flex tw-justify-center tw-w-full tw-h-full">
            <img
              src="@/assets/img/ecommerce/compra-rechazada.svg"
              class="tw-object-contain tw-w-full tw-h-full"
              alt=""
            />
          </div>
        </div>
        <div class="tw-col-span-2 tw-flex tw-items-center">
          <p
            class="tw-m-0 tw-font-roboto tw-text-xs lg:tw-text-sm tw-text-center"
          >
            ¿Estás seguro de cancelar tu solicitud?
          </p>
        </div>
      </div>
      <div class="tw-flex tw-justify-between tw-items-center tw-gap-3">
        <div class="tw-btn-secondary-white" @click="goBackCancel()">
          <p class="tw-m-0">Volver</p>
        </div>
        <div class="tw-btn-primary-red" @click="cancelRequest()">
          <p class="tw-m-0">Cancelar</p>
        </div>
      </div>
    </div>
  </div>
</template>
